<template>
  <div class="applyproj">
    <div class="top">
      <router-link to="/"><h1>易备网<p>etb618.com</p></h1></router-link>
      <div class="topContent">
        <p class="title">锅炉融资</p>
        <h2>产业联盟</h2>
        <div class="topline">
          <span></span>
          <span></span>
        </div>
        <p class="subs">工业锅炉采购&nbsp;&nbsp;设备安装</p>
        <p class="subs">锅炉贷款安装&nbsp;&nbsp;工程验收</p>
        <p class="subs">先使用后付款&nbsp;&nbsp;锅炉租赁</p>
      </div>
    </div>
    <!-- 关于我们 -->
    <div class="section">
      <div class="title">
        <h2>关于我们</h2>
        <p class="subTitle">About us</p>
      </div>
      <div class="content">
        <p>“易备网”帮助您处理工业锅炉工程中的设备采购、安装及验收等全部工作，并利用产业联盟服务模式解决您的资金压力问题。如果您的企业需要采购、安装、更换、改造或维修锅炉设备但苦于无法从银行贷款，存在资金压力，我们帮您解决。</p>
      </div>
    </div>
    <!-- 方案 -->
    <div class="section">
      <div class="title">
        <h2>我们的方案</h2>
      </div>
      <div class="sols">
        <div class="sol-item">
          <h3 style="background: orange;">可分期采购</h3>
          <p>此种方式包含锅炉房范围内全套设备及设计、安装、验收等相关配套服务，类似于房屋或汽车的分期按揭购买方式，区别在于没有第三方金融机构参与，以购买的设备作为抵押物进行设备类融资，还款周期最长可分180期，合同完成后进行设备产权转移，可按季度、年度开具发票，包含相应周期的维保及运行服务。</p>
        </div>
        <div class="sol-item">
          <h3 style="background: green;">可租赁使用</h3>
          <p>此种方式包含锅炉房范围内的全套设备及设计、安装、验收等相关配套服务，类似于房屋或汽车租赁，租赁周期最短24期，包含全部维保服务及相应周期运行服务，不涉及产权转移，可按季度、年度开具发票。</p>
        </div>
      </div>
    </div>
    <!-- 案例 -->
    <div class="section">
      <div class="title">
        <h2>经典案例</h2>
      </div>
      <div class="cases">
        <van-grid :border="false" :column-num="2">
          <van-grid-item v-for="(item, index) in cases" :key="index" @click="onClickCase(index)">
            <van-image slot="icon" class="case-thumb" :src="item.thumb" />
            <div slot="text">{{item.name}}</div>
          </van-grid-item>
        </van-grid>
        <div class="actions">
          <van-button type="info" round url="https://www.xcboiler.com/guolu/index.html">更多案例</van-button>
          <van-button type="info" round @click="onDownloadFile">产品手册下载</van-button>
        </div>
      </div>
    </div>
    <!-- 申请流程 -->
    <div class="section">
      <div class="title">
        <h2>申请流程</h2>
      </div>
      <div class="steps">
        <img src="https://etb618.oss-cn-beijing.aliyuncs.com/index/leaserent/steps.png" />
      </div>
    </div>
    <!-- 优势 -->
    <div class="section">
      <div class="title">
        <h2>我们能做什么</h2>
        <p class="subTitle">
          <img src="../assets/what.png" />
        </p>
      </div>
      <div class="advantages">
        <div class="advantage-item">
          <img src="../assets/youshi1.png" />
          <div class="desc">
            <p class="name">一站式配套服务</p>
            <p>产业联盟大量知名品牌优质设备厂商一站式配套服务</p>
          </div>
        </div>
        <div class="advantage-item">
          <img src="../assets/youshi2.png" />
          <div class="desc">
            <p class="name">一对一项目顾问</p>
            <p>一对一项目顾问帮助您解决项目流程中涉及到的全部问题</p>
          </div>
        </div>
        <div class="advantage-item">
          <img src="../assets/youshi3.png" />
          <div class="desc">
            <p class="name">独创的商业模式</p>
            <p>独创的商业模式支持利用分期付款减轻您的付款压力</p>
          </div>
        </div>
        <div class="advantage-item">
          <img src="../assets/youshi4.png" />
          <div class="desc">
            <p class="name">完整施资，专业售后</p>
            <p>完整的施工资质及全套专业持证施工人员解决后顾之忧</p>
          </div>
        </div>
        <div class="advantage-item">
          <img src="../assets/youshi5.png" />
          <div class="desc">
            <p class="name">平台化操作</p>
            <p>全流程网络平台化操作使分期付款更加便捷、一目了然</p>
          </div>
        </div>
        <div class="advantage-item">
          <img src="../assets/youshi6.png" />
          <div class="desc">
            <p class="name">优质施工方案</p>
            <p>大量的优质工程案例保证施工品质，让您能够放心将项目交给我们</p>
          </div>
        </div>
      </div>
    </div>
    <div class="fix-btn">
      <van-button type="info" round to="/applyprojForm">项目资料提交</van-button>
    </div>
  </div>
</template>

<script>
import Fetch from '../common/fetch';
import { ImagePreview } from 'vant';

export default {
  name: 'ApplyProj',
  data () {
    return {
      cases: [
        {
          name: '故宫博物院',
          thumb: 'https://etb618.oss-cn-beijing.aliyuncs.com/index/leaserent/gg.jpg',
          url: 'https://etb618.oss-cn-beijing.aliyuncs.com/index/leaserent/gg_xc.jpg',
        },
        {
          name: '人民大会堂',
          thumb: 'https://etb618.oss-cn-beijing.aliyuncs.com/index/leaserent/dht.jpg',
          url: 'https://etb618.oss-cn-beijing.aliyuncs.com/index/leaserent/dht_xc.jpg',
        },
        {
          name: '大兴机场',
          thumb: 'https://etb618.oss-cn-beijing.aliyuncs.com/index/leaserent/dxjc.jpg',
          url: 'https://etb618.oss-cn-beijing.aliyuncs.com/index/leaserent/dxjc_xc.jpg',
        },
        {
          name: '北京大学',
          thumb: 'https://etb618.oss-cn-beijing.aliyuncs.com/index/leaserent/bjdx.jpg',
          url: 'https://etb618.oss-cn-beijing.aliyuncs.com/index/leaserent/bjdx_xc.jpg',
        },
        {
          name: '中关村总部基地',
          thumb: 'https://etb618.oss-cn-beijing.aliyuncs.com/index/leaserent/zgc.jpg',
          url: 'https://etb618.oss-cn-beijing.aliyuncs.com/index/leaserent/zgc_xc.jpg',
        },
        {
          name: '中国尊',
          thumb: 'https://etb618.oss-cn-beijing.aliyuncs.com/index/leaserent/zgz.jpg',
          url: 'https://etb618.oss-cn-beijing.aliyuncs.com/index/leaserent/zgz_xc.jpg',
        },
      ],
    }
  },
  mounted() {
    window.scrollTo(0,0);
  },
  methods: {
    onClickCase(index) {
      const { url } = this.cases[index];
      ImagePreview([url]);
    },

    onDownloadFile() {
      Fetch.downloadFile('https://etb618.oss-cn-beijing.aliyuncs.com/index/leaserent/%E8%81%94%E7%9B%9F%E4%BA%A7%E5%93%81%E6%89%8B%E5%86%8C.pdf', '联盟产品手册');
    },
  },
}
</script>

<style lang="scss" scoped>
.applyproj {
  background: #fff;
  .top {
    position: relative;
    min-height: 280px;
    background-image: url('https://etb618.oss-cn-beijing.aliyuncs.com/index/leaserent/bg1.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    padding: 20px 16px 15px;
    h1 {
      font-weight: bold;
      font-size: 24px;
      line-height: 25px;
      color: #fff;
      margin-bottom: 20px;
      p {
        margin-top: 5px;
        line-height: 16px;
        font-size: 16px;
      }
    }
    .topContent {
      margin-top: 50px;
      letter-spacing: .4em;
      .title {
        font-size: 16px;
      }
      h2 {
        color: #fff;
        font-size: 28px;
        font-weight: bolder;
        letter-spacing: .45em;
      }
      .topline {
        width: 155px;
        height: 2px;
        line-height: 2px;
        margin-bottom: 20px;
        span {
          display: inline-block;
          width: 50%;
          height: 2px;
        }
        span:nth-child(1) {
          background-color: red;
        }
        span:nth-child(2) {
          background-color: blue;
        }
      }
      .subs {
        margin: 10px 0;
      }
    }
  }

  .section {
    margin: 30px 0 40px;
    font-size: 15px;
    background: #fff;
    .title {
      text-align: center;
      h2 {
        font-size: 24px;
        font-weight: bold;
      }
      .subTitle {
        margin-top: 5px;
        color: #00a0ff;
        font-weight: bold;
        font-size: 20px;
        img {
          max-width: 200px;
        }
      }
    }
    .content {
      padding: 16px;
      line-height: 1.8;
      letter-spacing: 3px;
      text-indent: 2em;
      text-align: justify;
    }
    .sols {
      padding: 16px 0;
      display: flex;
      flex-direction: column;
      .sol-item {
        overflow: hidden;
        padding: 10px 16px;
        color: #fff;
        text-align: center;
        h3 {
          text-align: center;
          margin-bottom: 10px;
          display: inline-block;
          padding: 0 1em;
          height: 40px;
          line-height: 40px;
          border-radius: 20px;
        }
        p {
          color: #222;
          text-align: justify;
          text-indent: 2em;
          line-height: 1.5;
        }
      }
    }
    .cases {
      .case-thumb {
        border-radius: 8px;
        overflow: hidden;
      }
      .actions {
        display: flex;
        justify-content: space-around;
        button {
          width: 120px;
        }
      }
    }
    .steps {
      padding: 16px 5px;
    }
    .advantages {
      padding: 0 16px;
      .advantage-item {
        position: relative;
        display: flex;
        align-items: center;
        margin: 10px 0;
        img {
          width: 50%;
          flex-shrink: 0;
        }
        .desc {
          padding: 0 10px;
          font-size: 12px;
          color: #666;

          .name {
            text-align: center;
            font-size: 15px;
            font-weight: bold;
            color: #222;
            margin-bottom: 1em;
          }
        }
      }
      .advantage-item:nth-child(2n) img {
        order: 1;
      }
    }
  }
}

.applyproj {
  padding-bottom: 50px;
  .fix-btn {
    position: sticky;
    bottom: 50px;
    text-align: center;
  }
}

</style>